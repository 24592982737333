export default origin = [
  {
    type: "dxm",
    start: "2022-09-27",
    end: "2025-03-27",
    range: 6,
    interest: 1137,
  },
  {
    type: "dxm",
    start: "2022-10-19",
    end: "2026-01-19",
    range: 3,
    interest: 1090,
  },
  {
    type: "dxm",
    start: "2022-11-25",
    end: "2025-05-25",
    range: 3,
    interest: 575,
  },
  {
    type: "dxm",
    start: "2022-12-03",
    end: "2025-06-03",
    range: 3,
    interest: 575,
  },
  {
    type: "dxm",
    start: "2022-10-15",
    end: "2025-10-15",
    range: 3,
    interest: 1090,
  },
  {
    type: "dxm",
    start: "2022-09-11",
    end: "2024-12-11",
    range: 3,
    interest: 556,
  },
  {
    type: "dxm",
    start: "2022-09-16",
    end: "2025-03-16",
    range: 3,
    interest: 562,
  },
  {
    type: "dxm",
    start: "2019-12-31",
    end: "2024-12-31",
    range: 3,
    interest: 562,
  },
  {
    type: "dxm",
    start: "2022-11-14",
    end: "2025-02-14",
    range: 3,
    interest: 562,
  },
  {
    type: "zgc",
    start: "2023-01-26",
    end: "2026-01-26",
    range: 6,
    interest: 1175,
  },
  {
    type: "zgc",
    start: "2023-02-04",
    end: "2026-02-04",
    range: 6,
    interest: 1175,
  },
  {
    type: "zgc",
    start: "2022-10-12",
    end: "2026-04-12",
    range: 3,
    interest: 550,
  },
  {
    type: "zgc",
    start: "2022-11-13",
    end: "2027-05-13",
    range: 3,
    interest: 500,
  },
  {
    type: "zgc",
    start: "2022-10-29",
    end: "2027-07-29",
    range: 3,
    interest: 500,
  },
  {
    type: "zgc",
    start: "2022-10-09",
    end: "2026-04-09",
    range: 1,
    interest: 102,
  },
  {
    type: "zgc",
    start: "2022-10-10",
    end: "2026-05-10",
    range: 1,
    interest: 34,
  },
  {
    type: "zgc",
    start: "2022-09-15",
    end: "2026-04-15",
    range: 1,
    interest: 176,
  },
  {
    type: "zgc",
    start: "2023-03-29",
    end: "2026-04-29",
    range: 1,
    interest: 35,
  },
  {
    type: "zgc",
    start: "2022-11-30",
    end: "2027-11-30",
    range: 3,
    interest: 500,
  },
  {
    type: "zgc",
    start: "2023-02-14",
    end: "2028-02-14",
    range: 7,
    dateType: "days",
    interest: 37,
  },
  {
    type: "zgc",
    start: "2023-02-20",
    end: "2028-02-20",
    range: 7,
    dateType: "days",
    interest: 37,
  },
  {
    type: "zgc",
    start: "2023-02-01",
    end: "2027-11-01",
    range: 3,
    interest: 500,
  },
  {
    type: "zgc",
    start: "2023-02-24",
    end: "2028-02-24",
    range: 3,
    interest: 500,
  },
  {
    type: "zgc",
    start: "2023-09-22",
    end: "2028-09-22",
    range: 3,
    interest: 437,
  },
  {
    type: "zgc",
    start: "2024-01-27",
    end: "2029-01-27",
    range: 3,
    interest: 430,
  },   
  {
    type: "zgc",
    start: "2024-04-06",
    end: "2029-04-06",
    range: 3,
    interest: 393,
  },
  {
    type: "zgc",
    start: "2024-03-25",
    end: "2029-03-25",
    range: 3,
    interest: 418,
  },    
  {
    type: "zgc",
    start: "2024-05-04",
    end: "2029-05-04",
    range: 3,
    interest: 393,
  },
  {
    type: "zgc",
    start: "2024-05-17",
    end: "2027-05-17",
    range: 3,
    interest: 362,
  },  
];
